<template>
  <el-dialog
    class="alert-dialog"
    :visible.sync="dialogFormVisible"
    @close="close"
  >
    <span slot="title" class="alarm-dialog-delete">
      Вы действительно хотите удалить таймслот?
    </span>
    <div>
      <h1 class="alert-title">⚠️ ВНИМАНИЕ! ⚠️</h1>
      <div class="alert-text">
        <div class="alert-delete-item">
          Eсли вы удалите таймслот, машина
          <span style="color: #f56c6c">
            <b>{{ dialogData['plate_truck'] }}</b>
          </span>
          не сможет приехать по нему.
        </div>
        <div class="alert-delete-item">
          <b> Дата:</b>
          <span style="color: #f56c6c">
            <b>
              {{ dateFormat.date }}&nbsp;&nbsp;{{ dateFormat.from }} -
              {{ dateFormat.to }}
            </b>
          </span>
        </div>
        <div class="alert-delete-item">
          <b> Терминал:</b>
          <span style="color: #f56c6c">
            <b> {{ dialogData['unload_name'] }}</b>
          </span>
        </div>
      </div>
    </div>
    <el-checkbox
      v-model="confirmDelete"
      class="alert-dialog__checkbox"
      label="Подтверждаю условия удаления тайм слота"
      border
    />
    <span slot="footer" class="dialog-footer">
      <el-button class="alert-button" type="info" @click="close">
        ОТМЕНИТЬ
      </el-button>
      <el-button
        :disabled="!confirmDelete"
        class="alert-button"
        type="danger"
        @click="submit"
      >
        УДАЛИТЬ
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { DATE_FORMAT_RU, GET_TIME } from '@/constants/date'
import { DR_GET_TERMINALS, DR_REMOVE_TIMESLOT } from '@/store/actions'
import { mapActions, mapGetters } from 'vuex'
import moment, { DEFAULT_TIMEZONE, textTimezones } from '@/core/date'

export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false,
    },
    dialogData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      confirmDelete: false,
    }
  },
  computed: {
    ...mapGetters({
      terminalsList: DR_GET_TERMINALS,
    }),
    dateFormat() {
      if (!this.dialogData) {
        return {}
      }
      let currentTerminal = this.terminalsList.find(
        item => item.id === this.dialogData.unload_id,
      )

      moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE])
      let dateFromTz = moment(this.dialogData.window_from).format('X')
      let dateToTz = moment(this.dialogData.window_to).format('X')

      moment.tz.setDefault(textTimezones[currentTerminal.timezone_utc_offset])

      return {
        date: moment(dateFromTz, 'X').format(DATE_FORMAT_RU),
        from: moment(dateFromTz, 'X').format(GET_TIME),
        to: moment(dateToTz, 'X').format(GET_TIME),
      }
    },
  },
  methods: {
    ...mapActions({
      removeTimeslot: DR_REMOVE_TIMESLOT,
    }),
    async submit() {
      try {
        this.removeTimeslot(this.dialogData.id)
        this.$message({
          type: 'info',
          message: 'Отправлен запрос на удаление таймслота',
          customClass: 'driver-message',
        })
        this.close()
      } catch (error) {
        this.$message({
          type: 'info',
          message: 'Удаление тайм слота не подтвержденно',
          customClass: 'driver-message',
        })
      }
    },
    close() {
      this.$emit('close')
      this.confirmDelete = false
    },
  },
}
</script>

<style lang="sass">
.alert-dialog
  .is-bordered
    font-size: 24px
    font-weight: bold
    height: 56px
    width: 100%
  .el-dialog
    @media only screen and (max-width: 456px)
      width: 90%!important
  .alert-delete-item
    margin-bottom: 8px
  .el-dialog__body
    margin-bottom: 0
    @media only screen and (max-width: 768px)
      padding: 10px 10px 0 10px
  .el-dialog__headerbtn
    @media only screen and (max-width: 768px)
      font-size: 32px!important
    @media only screen and (max-width: 456px)
      font-size: 24px!important
    i
      color: white!important
      font-size: 32px!important
      @media only screen and (max-width: 768px)
        font-size: 32px!important
  .el-dialog__header
    background-color: #F56C6C!important
    @media only screen and (max-width: 768px)
      padding: 10px 10px 10px 10px!important
  .alert-title
    font-weight: bold
    font-size: 1.8em
    margin: 0 0 24px 0
    @media only screen and (max-width: 768px)
      font-size: 1.8em
      line-height: 130% !important
    @media only screen and (max-width: 456px)
      font-size: 1.8em
      line-height: 130% !important
      margin: 0px 0px 8px 0px!important
  .alert-text
    font-size: 18px
    margin-bottom: 18px
    @media only screen and (max-width: 768px)
      font-size: 16px!important
    @media only screen and (max-width: 456px)
      font-size: 14px!important
      line-height: 130% !important
  .alarm-dialog-delete
    color: white
    line-height: 200%
    font-size: 20px!important
    text-transform: uppercase
    background-color: #F56C6C!important
    @media only screen and (max-width: 768px)
      font-size: 1.8em
      line-height: 140% !important
    @media only screen and (max-width: 456px)
      font-size: 1.14em!important
      line-height: 140% !important
  .el-dialog__footer
    padding: 0 30px 50px 30px!important
    @media only screen and (max-width: 768px)
      padding: 10px 10px 10px 10px!important
  .dialog-footer
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    @media only screen and (max-width: 768px)
      flex-direction: column
      padding: 0px!important
      margin: 0px !important
  .alert-button
    height: 50px
    width: 48%
    font-weight: bold
    @media only screen and (max-width: 768px)
      width: 100%
      margin: 0px!important
      margin-bottom: 24px!important
</style>
